<template>
  <section :class="$style.review">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
    </div>

    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Фото" prop="photo">
        <Uploader
          withoutDeleteRequest
          :files="form.photo && form.photo.original ? [form.photo] : []"
          @upload="uploadLogo"
          :class="$style.uploader"
        />
      </el-form-item>
      <el-form-item label="Имя" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="Описание" prop="description">
        <el-input v-model="form.description"></el-input>
      </el-form-item>
      <el-form-item label="Текст отзыва" prop="content">
        <el-input
          v-model="form.content"
          type="textarea"
          :autosize="{ minRows: 2 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="Приоритет" prop="orderField">
        <el-input-number v-model="form.orderField" :min="0"></el-input-number>
      </el-form-item>
      <div>
        <div :class="$style.addElem">
          <h3>Ссылки на соцсети</h3>
          <el-button
            size="small"
            type="primary"
            :class="$style.button"
            @click="addIconForReview()"
            >Добавить ссылку</el-button
          >
        </div>
        <Container
          @drop="onDropReviews"
          :non-drag-area-selector="'.drag-disabllinked'"
        >
          <Draggable v-for="(icon, ind) in form.socials" :key="ind">
            <div :class="$style.draggableSocials">
              <div :class="$style.dragIcon">
                <Icon name="draggable" :class="$style.icon" />
              </div>
              <el-form-item
                label="Ссылка"
                label-width="5rem"
                class="drag-disabled"
                :prop="'.socials.' + ind + '.link'"
                :rules="rules.socials.link"
              >
                <el-input v-model="form.socials[ind].link" />
              </el-form-item>
              <el-form-item
                label="Иконка"
                label-width="5rem"
                class="drag-disabled"
              >
                <el-select
                  v-model="form.socials[ind].iconName"
                  filterable
                  placeholder="Выберите иконку"
                  :popper-class="$style.iconOptions"
                >
                  <el-option
                    v-for="(item, index) in iconOptions"
                    :key="index"
                    :label="item"
                    :value="item"
                  >
                    <Icon :name="item" />
                    {{ item }}
                  </el-option>
                </el-select>
              </el-form-item>
              <div :class="$style.removeButton">
                <el-button
                  type="danger"
                  plain
                  circle
                  icon="el-icon-delete"
                  :class="$style.remove"
                  @click="removeReviewSocials(form.socials, ind)"
                />
              </div>
            </div>
          </Draggable>
        </Container>
      </div>
    </el-form>
  </section>
</template>

<script>
import delivery from '@/delivery'
import Icon from '@/components/atoms/Icon'
import notifications from '@/mixins/notifications'

import Uploader from '@/components/moleculs/AddwineUploader.vue'
export default {
  components: {
    Uploader,
    Icon,
  },
  mixins: [notifications],
  data() {
    return {
      iconOptions: ['instagram', 'facebook', 'youtube', 'telegram'],
      form: {
        photo: {
          caption: '',
          isActive: true,
          order_field: 0,
          original: '',
        },
        name: '',
        socials: [],
        description: '',
        content: '',
        link: '',
        orderField: 0,
        iconName: 0,
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        socials: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          iconName: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
        orderField: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            type: 'number',
            message: 'Пожалуйста, введите число',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        content: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        link: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        iconName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
      },
    }
  },
  async created() {
    await this.getReviews()
  },
  methods: {
    async getReviews() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.ReviewsActions.getById(
          this.$route.params.id,
        )
      if (error) return

      this.form = { ...value }

      loading.close()
    },
    addIconForReview() {
      this.form.socials.push({
        iconName: '',
        link: '',
      })
    },
    onDropReviews(dropResult) {
      this.onDropReviewSocials(dropResult)
    },
    onDropReviewSocials(dropResult) {
      this.form.socials = this.applyDrag(this.form.socials, dropResult)
    },
    removeReviewSocials(socials, index) {
      socials.splice(index, 1)
    },
    uploadLogo(photo) {
      this.form.photo = photo[0]
    },
    validateLogo(_, value, callback) {
      value && value.original
        ? callback()
        : callback(new Error('Пожалуйста, введите лого'))
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const data = {
            ...this.form,
            orderField: Number(this.form.orderField),
          }
          const { error } =
            await delivery.ContentServiceCore.ReviewsActions.update(
              this.$route.params.id,
              data,
            )
          if (!error) {
            this.showNotification('Отзыв успешно отредактирован', 'success')
            this.$router.push('/addwine/content/reviews')
          }
          loading.close()
        }
      })
    },
    removeItem(index, fieldName) {
      this.form[fieldName].splice(index, 1)
    },
  },
}
</script>

<style lang="scss" module>
.review {
  padding: 1rem;

  .form {
    & > div > label {
      text-align: left;
    }
    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }
  }
  .wrapper {
    @include stickyWrapper;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
  }
}
.draggableSocials {
  display: flex;
  padding: 0.5rem 0;
}
.dragIcon {
  margin-left: 1rem;
}
.icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0.6rem;
  cursor: pointer;
}
.iconOptions {
  li {
    padding-top: 1rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      max-width: 2rem;
    }
  }
}
</style>
